(function ($, Drupal, AccessibleDisclosureButton) {
  var contentBlockSitewideBanner = {
    init: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.$module = $('.js-content-block-sitewide-banner--v1', context);
      self.$triggers = $('.js-content-block-sitewide-banner-trigger', self.$module);
      var disclosureButton = new AccessibleDisclosureButton(self.$triggers.get(0));
      var $body = $('body');
      var observer = $();
      var moduleHeight = 0;
      var moduleCookieName = '';
      var hasModuleCookie = false;

      if (self.$module.length < 1) {
        return false;
      }
      moduleCookieName = self.$module.data().moduleCookieName;
      hasModuleCookie = $.cookie(moduleCookieName);
      if (!!hasModuleCookie) {
        self.$module.detach();

        return;
      }
      self.$module.removeClass('hidden');
      disclosureButton.init();
      $body.addClass('content-block-sitewide-banner--enabled');
      moduleHeight = self.$module.outerHeight(true);
      $(document).trigger('contentBlock.sitewideBanner', [moduleHeight]);

      if (self.$triggers.data('expires')) {
        observer = new MutationObserver(function (mutationList) {
          mutationList.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
              $body.removeClass('content-block-sitewide-banner--enabled');
              $(document).trigger('contentBlock.sitewideBanner', [0]);
              self.setCookie();
              self.$module.detach();
            }
          });
        });
        observer.observe(self.$triggers.get(0), {
          childList: false,
          attributes: true
        });
      }
    },
    setCookie: function () {
      var self = this;

      $.cookie(self.$triggers.attr('aria-controls'), 1, {
        path: '/',
        expires: self.$triggers.data('expires')
      });
    }
  };

  Drupal.behaviors.contentBlockSitewideBannerV1 = {
    $module: $(),
    $triggers: $(),
    attached: false,
    attach: function (context) {
      contentBlockSitewideBanner.init(context);
    }
  };

  $(document).on('contentBlock.signupSuccess', function () {
    contentBlockSitewideBanner.setCookie();
  });
})(jQuery, Drupal, window.AccessibleDisclosureButton);
